import { mapMutations } from 'vuex';

const topicPostMixin = {
  data() {
    return {
      lists: [],
      exInfo: {},
      userUnlockPostCount: 0,
      detail: null,
      userLockAll: true,
    };
  },
  computed: {
    groupID() {
      return this.$route.params.groupID || 0;
    },
    replyPostLink() {
      return this.groupID ? `api/group/${this.groupID}/topic/reply` : 'api/user/topic/post/reply';
    },
    groupMangers() {
      if (this.groupID > 0 && this.group) {
        const admins = this.group.admins.map((member) => member.uid);
        return [...admins, this.group.author.uid];
      }
      return [];
    },
    renderType() {
      return this.groupID > 0 ? 'group' : '';
    },
  },
  watch: {
    userUnlockPostCount(val) {
      this.userLockAll = this.detail ? Number(val) === Number(this.detail.totalPostCount) : true;
    },
  },
  methods: {
    ...mapMutations(['OPEN_REPLY_FORM', 'CLOSE_REPLY_FORM']),
    handlerPostDelete(data) {
      const { postid, deleteUrl } = data;
      const { groupID } = this.$route.params;
      const url = undefined === groupID ? `${deleteUrl}/${postid}` : deleteUrl;
      console.log(`处理删帖${postid},处理地址${url}`);
      return new Promise((resolve) => {
        this.$request.get(url).then((res) => {
          console.log(`处理结束${res.data}`);
          resolve(data);
        });
      });
    },
    onPostDel(postid) {
      const tempLists = this.lists;
      this.lists = tempLists.filter((item) => Number(item.id) !== Number(postid));
      if (this.$route.meta.deletedBack) {
        this.$router.go(-1);
      }
      this.$notify({
        type: 'success',
        message: '操作成功',
      });
      this.$root.$emit('afterPostDeleted', postid);
      console.log(`${postid}已经删除`);
    },
    onPostHide(postid) {
      const tempLists = this.lists;
      this.lists = tempLists.filter((item) => Number(item.id) !== Number(postid));
      this.$notify({
        type: 'success',
        message: '操作成功',
      });
      this.$root.$emit('afterPostHide', postid);
    },
    onPostReply(data) {
      const that = this;
      const temp = data;
      temp.topicid = this.topicid;
      that.exInfo = temp;
      that.OPEN_REPLY_FORM();
    },
    onAfterReply(res) {
      const that = this;
      const {
        reply, next, breakList, topicOldestPostId,
      } = res.data;
      const tempLists = that.lists;
      that.lists = tempLists.map((tl) => {
        const temp = tl;
        if (Number(temp.id) === Number(reply.post_id)) {
          if (temp._unlock) {
            temp._unlock = false;
          }
          temp.reply.push(reply);
        }
        return temp;
      });
      if (next) {
        const nextIndex = that.lists.findIndex((item) => Number(item.id) === Number(next.id));
        if (nextIndex === -1) {
          // 真解锁
          let tempCount = Number(that.userUnlockPostCount);
          tempCount += 1;
          that.userUnlockPostCount = tempCount;
          next._unlock = topicOldestPostId !== next.id;
          // 之前因为有新的不会显示已经解锁过的帖子才把break合进来
          // if (breakList.length > 0 && breakList[breakList.length - 1].id > next.id) {
          //   that.lists.push(...breakList);
          // }
          if (that.$route.name !== 'app-topic-post-detail') {
            that.$notify({
              type: 'success',
              message: '您有新内容解锁',
              duration: 3000,
            });
            const temp = [...this.lists, next];
            temp.sort((f, s) => s.created_at - f.created_at);
            that.lists = temp;
          }
        }
      } else {
        that.userUnlockPostCount = that.detail ? that.detail.totalPostCount : 0;
        if (breakList.length > 0) {
          that.lists.push(...breakList);
        }
      }

      that.CLOSE_REPLY_FORM();
    },
    onReplyFormCancel() {
      this.CLOSE_REPLY_FORM();
    },
  },
};

export default topicPostMixin;
