<template>
  <div>
    <van-list v-model="loading" :finished="finished" @load="getPosts" :offset="50">
      <item
        theme="group"
        v-for="(item, index) in lists"
        :key="item.id"
        :info="item"
        :queue="index"
        :lock-all="canIcreate"
        :call-post-del="handlerPostDelete"
        :is-stranger="false"
        :can-hidden-post="canHiddenPost"
        @postDel="onPostDel"
        @postReply="onPostReply"
        @postHide="onPostHide"
        @click.native.stop="e => goDetail(item.group_id, item.id, e)"
      ></item>
    </van-list>
    <reply-form
      @afterReply="onAfterReply"
      @cancel="onReplyFormCancel"
      :post-link="replyPostLink"
      :ex-info="exInfo"
    ></reply-form>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import topicPostMixin from '@/mixins/topic-post';
import forbiddenMixin from '@/mixins/forbidden';
import item from '../timeline/_item.vue';
import replyForm from '../common/_reply-form.vue';

export default {
  name: 'c_post-list-container',
  props: {
    renderType: {
      /**
       * list
       * single
       */
      type: String,
      required: true,
      default: 'list',
    },
    groupid: {
      /**
       * groupid > 0
       */
      type: Number,
      required: true,
      default: 0,
    },
    postid: {
      type: Number,
      default: 0,
    },
    hasRole: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    replyForm,
    item,
  },
  mixins: [forbiddenMixin, topicPostMixin],
  data() {
    return {
      loading: false,
      finished: false,
      replyPostLink: `api/group/${this.groupid}/post/reply`,
      exInfo: {},
      canIcreate: false,
      lists: [],
      group: null,
    };
  },
  computed: {
    link() {
      return this.renderType === 'list'
        ? `api/group/${this.groupid}/post/list`
        : `api/group/${this.groupid}/post/detail/${this.postid}`;
    },
    canHiddenPost() {
      return this.hasRole;
    },
  },
  watch: {
    canIcreate(val) {
      this.$emit('postLockSwitch', val);
    },
    group(val) {
      this.$emit('getGroup', val);
    },
  },
  methods: {
    ...mapMutations(['OPEN_REPLY_FORM', 'CLOSE_REPLY_FORM', 'ADD_GROUP_POST_CLEAR_COUNT']),
    getPosts() {
      const that = this;
      that.$request
        .get(that.link)
        .then((res) => {
          const { data } = res;
          if (that.renderType === 'list') {
            that.lists.push(...data.lists);
            that.canIcreate = data.nextLockPostId === false;
          } else {
            that.lists.push(data);
            that.group = data.group;
            that.canIcreate = true;
            that.$emit('getPost', data);
          }
        })
        .catch((err) => {
          const url = err.data ? `/friend/${err.data.author_uid}` : '';
          if (err.status === 403 || err.status === 500) {
            this.notifyForbidden('group', err.message, '查看组长', url);
          }
          if (err.status === 404) {
            const msg = url ? '该内容已消失<br/>请查看发布人其他内容' : '该内容已消失';
            this.notifyNotFound(msg, url || '/');
          }
        })
        .finally(() => {
          that.requesting = false;
          that.finished = true;
        });
    },
    onPostReply(data) {
      const that = this;
      const temp = data;
      temp.groupid = this.id;
      that.exInfo = temp;
      that.OPEN_REPLY_FORM();
    },
    onAfterReply(res) {
      const that = this;
      const {
        next, reply, breakList, topicOldestPostId,
      } = res.data;
      const tempLists = that.lists;
      let isHandleUnlock = false;
      that.lists = tempLists.map((tl) => {
        const temp = tl;
        if (Number(temp.id) === Number(reply.post_id)) {
          temp.reply.push(reply);
          if (temp._unlock) {
            temp._unlock = false;
            isHandleUnlock = true;
          }
        }
        return temp;
      });
      // 回复更新解锁数据
      if (next && tempLists.findIndex((postItem) => Number(postItem.id) === Number(next.id)) === -1) {
        that.$notify({
          type: 'success',
          message: '您有新内容解锁',
          duration: 3000,
        });
        next._unlock = Number(topicOldestPostId) !== next.id;
        if (breakList.length > 0) {
          if (breakList[breakList.length - 1].id > next.id) {
            that.lists.push(...breakList);
          }
        }
        that.lists.push(next);
      } else {
        if (breakList.length > 0) {
          that.lists.push(...breakList);
        }
        that.canIcreate = true;
      }
      if (isHandleUnlock) {
        // 更新解锁数
        that.ADD_GROUP_POST_CLEAR_COUNT(that.groupid);
      }
      that.CLOSE_REPLY_FORM();
    },
    onReplyFormCancel() {
      this.CLOSE_REPLY_FORM();
    },
    goDetail(groupid, id, e) {
      const tagName = e.target.tagName.toLowerCase();
      if (this.$route.name !== 'app-group-post-detail' && tagName !== 'a') {
        this.$router.push({
          path: `/group/${groupid}/post/${id}`,
        });
      }
    },
    //  onPostDel(postid) {
    //   console.log(`已经删除了${postid}`);
    // },
    // handlerPostDelete() {
    //   return new Promise((resolve) => {
    //     setTimeout(() => {
    //       resolve();
    //     }, 2000);
    //   });
    // },
  },
};
</script>

<style></style>
