const forbiddenMixin = {
  data() {
    return {
      forbidden: false,
      notFound: false,
    };
  },
  mounted() {
    this.forbidden = false;
  },
  methods: {
    notifyForbidden(position, message, btText, confirmUrl) {
      this.forbidden = true;
      const url = `${confirmUrl}?from=forbidden`;
      this.$root.$emit('forbidden', {
        position,
        message,
        btText,
        confirmUrl: url,
      });
    },
    notifyNotFound(message, url) {
      this.notFound = true;
      this.$root.$emit('notfound', {
        message, url,
      });
    },
  },
};

export default forbiddenMixin;
