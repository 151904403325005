<template>
  <div class="page-add-bt-container">
    <div class="inner">
      <svg-icon :icon-class="icon" :class="themeColor" :style="selfColor"></svg-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: 'c_add-bt',
  props: ['themeColor', 'icon', 'color'],
  computed: {
    selfColor() {
      return this.color ? `color:${this.color}` : '';
    },
  },
};
</script>

<style></style>
