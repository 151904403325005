<template>
  <!-- 小组里的帖子列表 -->
  <div class="group__content-list-container">
    <group-post-item
      render-type="list"
      :groupid="Number(id)"
      :postid="0"
      :hasRole="hasRole"
      @postLockSwitch="switchCanICreate"
    ></group-post-item>
    <!-- 发帖按钮 -->
    <add-bt
      v-if="canIcreate"
      icon="icon-plus-green"
      theme-color="green"
      @click.native="onCreateGroupPost"
    ></add-bt>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import groupPostItem from './_post.vue';
import addBt from '../common/_add-bt.vue';

export default {
  name: 'group-detail-posts',
  props: ['groupID', 'currentUnlockLists'],
  components: {
    addBt,
    groupPostItem,
  },
  data() {
    return {
      canIcreate: false,
    };
  },
  computed: {
    ...mapState(['user']),
    group() {
      return this.$parent.info;
    },
    hasRole() {
      if (this.group && this.user) {
        return (
          this.group.author.uid === this.user.uid
          || this.group.admins.find((item) => item.uid === this.user.uid) !== undefined
        );
      }
      return false;
    },
  },
  mounted() {
    this.$root.$on('afterPostDeleted', (postid) => {
      console.log('posts after post deleted');
      if (this.currentUnlockLists.indexOf(postid) >= 0) {
        this.SUBTRACT_GROUP_POST_CLEAR_COUNT(this.groupID);
      }
      this.SUBTRACT_GROUP_POST_TOTAL_COUNT(this.groupID);
    });
    this.$root.$on('afterPostHide', (postid) => {
      console.log('posts after post hide');
      if (this.currentUnlockLists.indexOf(postid) >= 0) {
        this.SUBTRACT_GROUP_POST_CLEAR_COUNT(this.groupID);
      }
      this.SUBTRACT_GROUP_POST_TOTAL_COUNT(this.groupID);
    });
  },
  destroyed() {
    this.$root.$off('afterPostDeleted');
    this.$root.$off('afterPostHide');
  },
  methods: {
    ...mapMutations(['SUBTRACT_GROUP_POST_CLEAR_COUNT', 'SUBTRACT_GROUP_POST_TOTAL_COUNT']),
    switchCanICreate(val) {
      this.canIcreate = val;
    },
    onCreateGroupPost() {
      this.$router.push({
        path: `/group/${this.groupID}/edit`,
      });
    },
  },
};
</script>

<style></style>
